import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import VueMarqueeSlider from 'vue-marquee-slider';

import {
  getMemberInfo, getUserNewsList, getPackage, country_list, getUserPackage, getDownloadLink, getCryptoInfo, getBankInfo, countryDepositList, getTicket
} from "@/system/request/api/api";

import './registerServiceWorker'

Vue.use(VueGoodTablePlugin);
Vue.use(VueMarqueeSlider);
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

new Vue({
  data() {
    return {
      isLoading: false,
      needRiskLevel: false,
      cannotUpgrade: true,
      userInfo: {
        password2: null,
        invest: 0,
        user_group_id: 0,
        rank: {
          rank_name_en: ''
        },
        package: {
          price: 0,
        },
        point1: 0,
        point2: 0,
        point3: 0,
        poin4: 0,
      },
      bankInfo: {},
      cryptoInfo: {},
      selectedWallet: '',
      currentAddress: '',
      username: '',
      memberList: [],
      country_code: '',
      countryOptions: [],
      countryDepositOptions: [],
      teamList: [],
      newsList: [],
      latestNews: [],
      packageList: [],
      userPackages: [],
      assetInfo: {
        static_bonus: 0,
        special_bonus: 0,
        dynamic_bonus: 0,
        level_bonus: 0,
      },
      variant: 'success',
      modal: {
        title: "",
        msg: "",
        action: false,
        error: false,
      },
      defaultCountry: {},
      globalSetting: { GAC_POOL: '0' },
      blockWithdraw: false,
      admin_replied: 0,
      gac_price: 5

    }
  },
  watch: {
    '$i18n.locale'() {
      this.getNewsList();
    },
    'userInfo': 'checkCurrentDate',
  },
  methods: {
    changeLan(lan) {
      localStorage.setItem('lan', lan);
      this.$i18n.locale = lan;

      // } else {
      //   this.$root.makeToast("warning", this.$t('coming_soon'));
      // }
    },
    getTicket(page) {
      var self = this;
      this.isLoading = true;
      var result = getTicket(page);
      result
        .then(function (value) {
          var dataList = value.data.data.ticket;
          self.isLoading = false;

          dataList.forEach(element => {
            if (element.ruid != null && element.user_read == 0) {
              self.admin_replied += 1;
              console.log(self.admin_replied);
            }

          });
        })
        .catch(function (error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    getPackageType() {
      var self = this;
      self.isLoading = true;
      var result = getPackage();
      result
        .then(function (value) {
          self.packageList = value.data.data.user_group;
          self.isLoading = false;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkCurrentDate() {
      this.cannotUpgrade = true;
      var todayDate = new Date().getDate();
      if (todayDate <= 3) {
        if (this.userInfo.user_group_id == 0 || this.userInfo.user_group_id >= 3) {
          this.cannotUpgrade = false;

        }
        if (this.userInfo.user_group_id >= 3 && this.userInfo.risk_level == 0)
          this.needRiskLevel = true;

      } else {
        if (this.userInfo.user_group_id == 0) {
          this.cannotUpgrade = false;

        }
      }

      console.log(this.cannotUpgrade);
    },
    getCountryList() {
      var self = this;
      self.countryOptions = [];
      this.isLoading = true;
      var result = country_list();
      result
        .then(function (value) {
          self.$root.isLoading = false;
          var rootDataList = value.data.data;
          self.defaultCountry = value.data.data.find(el => el.id == 2);

          for (let i = 0; i < rootDataList.length; i++) {
            rootDataList[i]["symbol"] = rootDataList[i].short_form;
            rootDataList[i]["value"] = rootDataList[i].id;
            rootDataList[i]["code"] = rootDataList[i].country_code;
            rootDataList[i]["rate"] = rootDataList[i].sell;
            rootDataList[i]["name"] = self.$i18n.locale == 'zh' ? rootDataList[i].name : rootDataList[i].name_en;

            self.countryOptions.push(rootDataList[i]);
          }
          const priorityCountries = ["Malaysia", "Singapore", "Brunei", "Indonesia", "Thailand", "Vietnam", "China"];
          const sortedCountries = self.countryOptions.sort((a, b) => {
            const indexA = priorityCountries.indexOf(a.name_en);
            const indexB = priorityCountries.indexOf(b.name_en);

            if (indexA === -1 && indexB === -1) {
              return 0;
            }
            if (indexA === -1) {
              return 1;
            }
            if (indexB === -1) {
              return -1;
            }

            return indexA - indexB;
          });

          console.log(sortedCountries);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getCountryDepositList() {
      var self = this;
      self.countryDepositOptions = [];
      this.isLoading = true;
      var result = countryDepositList();
      result
        .then(function (value) {
          self.$root.isLoading = false;
          var rootDataList = value.data.data;
          self.defaultCountry = value.data.data.find(el => el.id == 2);

          for (let i = 0; i < rootDataList.length; i++) {
            rootDataList[i]["symbol"] = rootDataList[i].short_form;
            rootDataList[i]["value"] = rootDataList[i].id;
            rootDataList[i]["code"] = rootDataList[i].country_code;
            rootDataList[i]["rate"] = rootDataList[i].sell;
            rootDataList[i]["name"] = (self.$i18n.locale == 'en' ? rootDataList[i].name_en : rootDataList[i].name) + ' (' + rootDataList[i].short_form + ')';

            self.countryDepositOptions.push(rootDataList[i]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getGlobalSetting() {
      var result = getDownloadLink();
      var self = this;
      this.isLoading = true;
      result
        .then(function (value) {
          self.isLoading = false;
          self.globalSetting = value.data.data.system;
          console.log(self.globalSetting.YESTERDAY_USD);
        })
        .catch(function () {
          self.isLoading = false;
        });
    },
    getPackage(id) {
      var self = this;
      self.isLoading = true;
      var result = getUserPackage(id);
      result
        .then(function (value) {
          self.isLoading = false;
          self.userPackages = value.data.data.user_package.data;
          // console.log(self.userPackages);

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getInfo() {
      var self = this;
      self.isLoading = true;
      var result = getMemberInfo();
      result
        .then(function (value) {
          self.isLoading = false;
          self.userInfo = value.data.data;
          if (self.userInfo.password2 == null) {
            self.$bvModal.show('modal-setupPwd');

          }
          if (self.userInfo.google_lock == 0) {
            self.$bvModal.show('modal-setupGA');

          }


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBankInfo() {
      var result = getBankInfo();
      var self = this;

      result.then(function (value) {
        if (value.data.data != null) {
          self.bankInfo = value.data.data;

          self.defaultCountry = self.countryOptions.find(el => el.id == self.bankInfo.bank_country);
          // self.blockWithdraw = false;
        }
        // else if (needAction) {
        //   self.modal.action = needAction;
        //   self.blockWithdraw = true;
        //   self.makeToast('info', self.$t('set_withdraw'), self.$t('set_withdraw_hint'));
        // }

      }).catch(function () {
      });
    },
    getCInfo() {
      var result = getCryptoInfo();
      var self = this;
      result
        .then(function (value) {
          if (value.data.data != null) {
            self.cryptoInfo = value.data.data;
            // self.blockWithdraw = false;
          }
          // else if (needAction) {
          //   self.modal.action = needAction;
          //   self.blockWithdraw = true;
          //   self.makeToast('info', this.$t('set_withdraw'), this.$t('set_withdraw_hint'));
          // }

        })
        .catch(function () { });
    },
    getNewsList() {
      var currentUserID = localStorage.getItem('userID');
      var blockList = [1005551, 1012964, 1012965, 1013110];
      var existUser = blockList.find((el) => currentUserID == el);
      console.log(existUser);
      if (existUser) {
        return;
      }
      var result = getUserNewsList(this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale, 1, 1);
      var self = this;
      result
        .then(function (value) {
          self.newsList = value.data.data.record.data;
          self.latestNews = value.data.data.latest.data;
          if (self.latestNews.length > 0) {
            var date = localStorage.getItem('dunShow');
            var today = new Date().getDate();
            if (date != today) {
              self.$bvModal.show('modal-news');

            }

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makeToastGAC(type, title, msg) {
      this.variant = type;
      this.modal.title = title;
      this.modal.msg = msg;
      this.$bvModal.show('modal-errorMsgGAC');
      // this.$bvToast.toast(msg, {
      //   // // title: ` ${variant || "default"}`,
      //   variant: variant,
      //   solid: true,
      // });
    },
    makeToast(type, title, msg) {
      this.variant = type;
      this.modal.title = title;
      this.modal.msg = msg;
      this.$bvModal.show('modal-errorMsg');
      // this.$bvToast.toast(msg, {
      //   // // title: ` ${variant || "default"}`,
      //   variant: variant,
      //   solid: true,
      // });
    },
    checkKYC() {
      if (this.userInfo.check_kyc.can_withdraw == 'approved') {
        this.$bvModal.show('withdraw');

      } else {
        this.modal = {
          title: this.$t('kyc'),
          msg: this.$t('kyc_hint'),
          action: false,
          error: true,
        };
        this.$bvModal.show('modal-errorMsg');

      }
    },
    clearErrorMsg() {
      this.$root.modal = {
        title: "",
        msg: "",
        action: false,
        error: false,
      };

    },
    updatePb() {
      var heighest = 0;
      var bottom = document.getElementsByClassName('withdraw-hint');
      var mainContent = document.getElementsByClassName('main-content');
      if (mainContent) {
        mainContent = mainContent[0];
        if (bottom) {
          for (let i = 0; i < bottom.length; i++) {
            if (bottom[i].offsetHeight > heighest) {
              heighest = bottom[i].offsetHeight;
              console.log(heighest);

            }
          }

        }
        if (mainContent)
          mainContent.setAttribute('style', 'padding-bottom:' + (heighest + 100) + 'px !important');


      }



    }
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
